var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-4" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-table",
                {
                  ref: "table",
                  attrs: {
                    title: "설비 목록",
                    columns: _vm.grid.columns,
                    gridHeight: _vm.grid.height,
                    data: _vm.grid.data,
                    filtering: false,
                    selection:
                      _vm.popupParam.selectData.length > 0
                        ? "multiple"
                        : "none",
                    columnSetting: false,
                    usePaging: false,
                    isExcelDown: false,
                    checkClickFlag: false,
                    editable: _vm.editable,
                    noDataLabel:
                      "정비계획할 설비를 추가하세요. 저장할 시 정비결과를 등록 할 수 있습니다.",
                    rowKey: "minEquipmentMaintenanceId",
                    checkDisableColumn: "disable",
                  },
                  on: { rowClick: _vm.rowClick },
                  scopedSlots: _vm._u([
                    {
                      key: "customArea",
                      fn: function ({ props, col }) {
                        return [
                          col.name === "complete" &&
                          _vm.popupParam.selectData.length > 0
                            ? [
                                _c("q-btn", {
                                  staticClass: "glossy",
                                  attrs: {
                                    round: "",
                                    size: "sm",
                                    editable: _vm.editable,
                                    disable: props.row.disable,
                                    color: "blue-6",
                                    icon: "save",
                                  },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.resultComplete3(props.row)
                                    },
                                  },
                                }),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "table-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.popupParam.selectData.length > 0
                            ? _c(
                                "q-btn",
                                {
                                  staticClass: "custom-btn",
                                  attrs: {
                                    icon: "help",
                                    color: "deep-purple-6",
                                    "text-color": "white",
                                    align: "center",
                                    round: "",
                                  },
                                },
                                [
                                  _c(
                                    "q-tooltip",
                                    {
                                      attrs: {
                                        anchor: "bottom left",
                                        self: "top left",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustomTop" },
                                        [_vm._v(" 버튼설명 ")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "tooltipCustom" },
                                        [
                                          _vm._v(
                                            " ④ 선택 정비완료 : 체크박스를 선택하여 정비완료 하는 버튼입니다."
                                          ),
                                          _c("br"),
                                          _vm._v(
                                            " ⑤ 일괄 정비결과 저장 : 설비목록과 설비별 정비결과를 일괄적으로 저장하는 버튼입니다."
                                          ),
                                          _c("br"),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.popupParam.selectData.length > 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "선택완료",
                                  icon: "save",
                                  color: "blue",
                                },
                                on: { btnClicked: _vm.resultComplete },
                              })
                            : _vm._e(),
                          _vm.editable &&
                          !_vm.disabled &&
                          _vm.popupParam.selectData.length === 0
                            ? _c("c-btn", {
                                attrs: {
                                  showLoading: false,
                                  label: "완료",
                                  icon: "check",
                                  color: "blue",
                                },
                                on: { btnClicked: _vm.resultComplete2 },
                              })
                            : _vm._e(),
                          _vm.editable && !_vm.disabled
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.updatesUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.grid.data,
                                  mappingType: "PUT",
                                  label:
                                    _vm.popupParam.selectData.length > 0
                                      ? "일괄 정비결과 저장"
                                      : "저장",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInspection,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "col-8" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "q-form",
                { ref: "editForm" },
                [
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: {
                        title:
                          _vm.result.equipmentName +
                          "(" +
                          _vm.result.equipmentNo +
                          ") 정비결과",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _vm.saveDisable && !_vm.disabled
                                ? _c("c-btn", {
                                    attrs: {
                                      url: _vm.updateUrl,
                                      isSubmit: _vm.isSave2,
                                      param: _vm.result,
                                      mappingType: "PUT",
                                      label: "정비결과 저장",
                                      icon: "save",
                                    },
                                    on: {
                                      beforeAction: _vm.saveItemInspection,
                                      btnCallback: _vm.saveCallback,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                required: "",
                                disabled: _vm.result.disable,
                                label: "정비유형",
                                codeGroupCd: "MDM_MAINTENANCE_KIND_CD",
                                type: "edit",
                                itemText: "codeName",
                                itemValue: "code",
                              },
                              model: {
                                value: _vm.result.equipmentCheckTypeCd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "equipmentCheckTypeCd",
                                    $$v
                                  )
                                },
                                expression: "result.equipmentCheckTypeCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-field", {
                              attrs: {
                                required: "",
                                disabled: _vm.result.disable,
                                label: "실제정비자",
                                name: "maintenanceUserId",
                                type: "user",
                              },
                              model: {
                                value: _vm.result.maintenanceUserId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "maintenanceUserId", $$v)
                                },
                                expression: "result.maintenanceUserId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                required: "",
                                disabled: _vm.result.disable,
                                editable: _vm.editable,
                                label: "실제정비일",
                                name: "maintenanceDate",
                              },
                              model: {
                                value: _vm.result.maintenanceDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "maintenanceDate", $$v)
                                },
                                expression: "result.maintenanceDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-select", {
                              attrs: {
                                required: "",
                                disabled: _vm.result.disable,
                                editable: _vm.editable,
                                codeGroupCd: "MIM_CHECK_RESULT_CD2",
                                type: "edit",
                                itemText: "codeName",
                                itemValue: "code",
                                name: "inspResultAfterMemCd",
                                label: "정비후 정비결과",
                              },
                              model: {
                                value: _vm.result.inspResultAfterMemCd,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "inspResultAfterMemCd",
                                    $$v
                                  )
                                },
                                expression: "result.inspResultAfterMemCd",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                disabled: _vm.result.disable,
                                editable: _vm.editable,
                                label: "정비시간",
                                range: true,
                                minuteStep: 10,
                                type: "time",
                                name: "maintenanceTimeList",
                              },
                              model: {
                                value: _vm.result.maintenanceTimeList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "maintenanceTimeList",
                                    $$v
                                  )
                                },
                                expression: "result.maintenanceTimeList",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c(
                              "c-field",
                              {
                                attrs: {
                                  disabled: _vm.result.disable,
                                  label: "정비부서 입회자",
                                  name: "memDeptEntrantUserId",
                                  type: "user",
                                },
                                model: {
                                  value: _vm.result.memDeptEntrantUserId,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.result,
                                      "memDeptEntrantUserId",
                                      $$v
                                    )
                                  },
                                  expression: "result.memDeptEntrantUserId",
                                },
                              },
                              [_vm._v(" >")]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                afterIcon:
                                  _vm.editable && !_vm.disabled
                                    ? [
                                        {
                                          name: "search",
                                          click: true,
                                          callbackName: "searchWorkPermit",
                                        },
                                        {
                                          name: "close",
                                          click: true,
                                          callbackName: "removeWorkPermit",
                                        },
                                      ]
                                    : null,
                                editable: _vm.editable,
                                close: false,
                                detail: true,
                                readonly: true,
                                label: "작업허가번호",
                                name: "workSummary",
                              },
                              on: {
                                searchWorkPermit: _vm.searchWorkPermit,
                                detailPop: _vm.detailWorkPermit,
                                removeWorkPermit: _vm.removeWorkPermit,
                              },
                              model: {
                                value: _vm.result.workSummary,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "workSummary", $$v)
                                },
                                expression: "result.workSummary",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "정비자 자격사항",
                                name: "maintenanceQualifications",
                              },
                              model: {
                                value: _vm.result.maintenanceQualifications,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "maintenanceQualifications",
                                    $$v
                                  )
                                },
                                expression: "result.maintenanceQualifications",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "정비원인",
                                rows: 2,
                                name: "maintenanceCause",
                              },
                              model: {
                                value: _vm.result.maintenanceCause,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "maintenanceCause", $$v)
                                },
                                expression: "result.maintenanceCause",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "정비내용",
                                rows: 2,
                                name: "maintenanceContent",
                              },
                              model: {
                                value: _vm.result.maintenanceContent,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "maintenanceContent",
                                    $$v
                                  )
                                },
                                expression: "result.maintenanceContent",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "정비결과요약",
                                rows: 2,
                                name: "maintenanceResultSummary",
                              },
                              model: {
                                value: _vm.result.maintenanceResultSummary,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "maintenanceResultSummary",
                                    $$v
                                  )
                                },
                                expression: "result.maintenanceResultSummary",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                rows: 2,
                                label: "관리자의 검토의견",
                                name: "reviewOpinionOfMananger",
                              },
                              model: {
                                value: _vm.result.reviewOpinionOfMananger,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "reviewOpinionOfMananger",
                                    $$v
                                  )
                                },
                                expression: "result.reviewOpinionOfMananger",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c(
                    "c-card",
                    {
                      staticClass: "cardClassDetailForm",
                      attrs: { title: "정비이력" },
                    },
                    [
                      _c(
                        "template",
                        { slot: "card-button" },
                        [
                          _c(
                            "q-btn-group",
                            { attrs: { outline: "" } },
                            [
                              _c("c-btn", {
                                attrs: { label: "출력", icon: "print" },
                                on: { btnClicked: _vm.btnRecord },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("template", { slot: "card-detail" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "보고서번호",
                                name: "reportNo",
                              },
                              model: {
                                value: _vm.result.reportNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "reportNo", $$v)
                                },
                                expression: "result.reportNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "고장심각도",
                                name: "failureSeverity",
                              },
                              model: {
                                value: _vm.result.failureSeverity,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "failureSeverity", $$v)
                                },
                                expression: "result.failureSeverity",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "고장모드",
                                name: "failureMode",
                              },
                              model: {
                                value: _vm.result.failureMode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "failureMode", $$v)
                                },
                                expression: "result.failureMode",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-text", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "누계횟수",
                                name: "totalNumber",
                              },
                              model: {
                                value: _vm.result.totalNumber,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "totalNumber", $$v)
                                },
                                expression: "result.totalNumber",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                disabled: _vm.result.disable,
                                editable: _vm.editable,
                                label: "운전정지시간",
                                range: true,
                                minuteStep: 10,
                                type: "time",
                                name: "stopDrivingTimeList",
                              },
                              model: {
                                value: _vm.result.stopDrivingTimeList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "stopDrivingTimeList",
                                    $$v
                                  )
                                },
                                expression: "result.stopDrivingTimeList",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                disabled: _vm.result.disable,
                                editable: _vm.editable,
                                label: "이용불능시간",
                                range: true,
                                minuteStep: 10,
                                type: "time",
                                name: "noUseTimeList",
                              },
                              model: {
                                value: _vm.result.noUseTimeList,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "noUseTimeList", $$v)
                                },
                                expression: "result.noUseTimeList",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                          },
                          [
                            _c("c-datepicker", {
                              attrs: {
                                disabled: _vm.result.disable,
                                editable: _vm.editable,
                                minuteStep: 10,
                                range: true,
                                label: "총가동시간",
                                type: "time",
                                name: "allDrivingTimeList",
                              },
                              model: {
                                value: _vm.result.allDrivingTimeList,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.result,
                                    "allDrivingTimeList",
                                    $$v
                                  )
                                },
                                expression: "result.allDrivingTimeList",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                          },
                          [
                            _c("c-textarea", {
                              attrs: {
                                editable: _vm.editable,
                                disabled: _vm.result.disable,
                                label: "관리자확인",
                                rows: 2,
                                name: "managerConfirm",
                              },
                              model: {
                                value: _vm.result.managerConfirm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.result, "managerConfirm", $$v)
                                },
                                expression: "result.managerConfirm",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }